import React from "react"
import TemplatePageGuide  from "@components/pageGuide/template"
import { Helmet } from "react-helmet"
import data from "@components/pageGuide/guides/guide28/dataPageGuide28"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>
        Ciclo de cuentas por cobrar: Qué es y cómo funciona
       [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/recursos/guias/ciclo-cuentas-cobrar/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Aprende en qué consiste un ciclo de cuentas por cobrar y cómo te ayuda para mejorar la efectividad las campañas de cobranzas de tu empresa."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Ciclo de cuentas por cobrar: Qué es y cómo funciona"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/recursos/guias/ciclo-cuentas-cobrar/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/open-Beex.png`}
      />
      <meta
        property="og:description"
        content="Aprende en qué consiste un ciclo de cuentas por cobrar y cómo te ayuda para mejorar la efectividad las campañas de cobranzas de tu empresa."
      />
    </Helmet>
    <TemplatePageGuide
      ebook="Cobranzas"
      location={location}
      typeGuide="guide28"
      clickHereHref="https://beexcc.com/blog/noticias-atencion-al-cliente/"
      data={data}
    />
  </div>
)

export default IndexPage
